import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';
import bottomLogo from '../Assets/bottomLogo.png';

function Footer() {
    const location = useLocation();

    const handleHomeClick = (event) => {
        if (location.pathname === '/') {
            event.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    return (
        <nav className="footer">
            <img src={bottomLogo} alt="Logo" className="footer-logo" />
            <div className="footer-content">
                <div className="column column-left">
                    <h3>KANJI SECURITY</h3>
                    <p>
                        <strong>Headquarters:</strong> Nicosia, Cyprus<br />
                        <strong>Offices:</strong> Atlanta, Brussels (EU),<br />
                        Phoenix, San Diego and Tampa
                    </p>
                    <p>Email: <a href="mailto:info@kanjisecurity.com">info@kanjisecurity.com</a></p>
                </div>
                <div className="column">
                    <Link to="/" onClick={handleHomeClick}>HOME</Link>
                    <Link to="/about">ABOUT</Link>
                    <Link to="/services">SERVICES</Link>
                    <Link to="/contact">CONTACT</Link>
                </div>
                <div className="column">
                    <button 
                        className="demo-button" 
                        onClick={() => window.open('https://meetings-eu1.hubspot.com/william-ochs', '_blank')}
                    >
                        BOOK APPOINTMENT
                    </button>
                    <p>Kanji Security (r) Inc.</p>
                    <p>All rights reserved</p>
                </div>
            </div>
        </nav>
    );
}

export default Footer;