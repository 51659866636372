// src/pages/ServicesPage.jsx
import React from 'react';
import './ServicesPage.css';
import bannerImage from '../Assets/rectangleKanjiBanner.png';
import globeImage from '../Assets/squareOrangeGlobe.png';
import riskImage from '../Assets/squareRiskManagement.png';
import aiImage from '../Assets/squareCyberai.png';
import HubSpotFormAboutPage from '../Components/HubSpotFormAboutPage';

function ServicesPage() {
  return (
    <div>
        <div className="kanji-banner">
            <img src={bannerImage} alt="Kanji Banner" className="kanji-banner-image"/>
            <div className="kanji-banner-text">Services</div>
        </div>
        <div className="about-page white-section">
          <h1 className="main-headline">What to expect from us</h1>
          <p className="white-header">
            The good news is, you're in the right the place. Our team and partner network consists of a wide skillset across a range of industries. 
            We're currently focused on vCISO (virtual Chief Information Security Officer) support. 
            If you're looking for something else, or not sure exactly what you're looking for, let's chat. Our network is larger and we can most likely 
            point you in the right direction.
          </p>
        </div>
        <div className="about-page orange-stripe">
            <img src={globeImage} alt="Globe" className="stripe-image" />
            <div className="stripe-text">
                <h2 className="header">Global vCISO Services</h2>
                <p className="paragraph">
                  Are you looking for flexible, on-demand virtual Chief Information Security Officer services covering North America and Europe? 
                  From tailored program design and development focused on the alignment of security initiatives with business goals to strategic planning, 
                  our goal is to provide leadership that is knowledgable and reliable.
                </p>
            </div>
        </div>
        <div className="about-page white-stripe-right">
            <div className="stripe-text">
                <h3 className="header">GRC Leadership</h3>
                <p className="paragraph">
                  Do you need comprehensive, strategic risk management support to navigate the complex regulatory landscapes of today? 
                  We are experts in governance, risk and compliance; and ensure robust data protection, privacy, and compliance with standards 
                  such as FedRAMP, ISO27001, CMMC, GDPR, C5, CRA, CSA, NIS2, EUCC, EUCS and more. By addressing your current security environment and challenges, we'll help implement 
                  a resilient solution that adapts to evolving risks and fosters sustainable business growth.
                </p>
            </div>
            <img src={riskImage} alt="Risk Management" className="stripe-image-right" />
        </div>
        <div className="gray-stripe">
            <img src={aiImage} alt="CyberAI Services" className="stripe-image" />
            <div className="stripe-text">
                <h2 className="header">CyberAI Consulting</h2>
                <p className="paragraph">
                  Looking to leverage the power of artificial intelligence to streamline your organization’s operational processes? So are we. 
                  By integrating cutting-edge AI technologies, we enable businesses to optimize workflows, improve resource allocation, and achieve scalable, 
                  sustainable growth through automation of repetitive security operations. Our goal? Enhance your decision-making efficiency, 
                  and free up your team to focus on high-impact initiatives.
                </p>
            </div>
        </div>
        <HubSpotFormAboutPage />
  </div>
  );
}

export default ServicesPage;